<template>
  <div>
    <!--begin::Dashboard-->
      <div class="card border-lg border-0 border-primary">
				<div class="card-body d-flex p-0">
					<div class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat" style="background-position: calc(100% + 0.5rem) bottom; background-size: 50% auto; background-image: url(media/svg/humans/custom-10.svg)">
						<h2 class="text-dark pb-5 font-weight-bolder">Mulai Sekarang</h2>
						<p class="text-dark-50 pb-5 font-size-h5">Verifikasi data harus lebih teliti,
						<br />tidak asal verifikasi saja,
						<br />baca terlebih dahulu datanya,
						<br />dan sesuai amanat</p>
					</div>
				</div>
			</div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "verifikasi",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verifikasi" }]);
  },
  methods: {
    //
  }
};
</script>
